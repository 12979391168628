import React from 'react'
import About from '../components/About'
import Banner from '../components/Banner'
import Faculties from '../components/Faculties'

const Home = () => {

    return (
        <div>
            <Banner />
            <About />
            <Faculties />
        </div>
    )
}

export default Home