import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const ContactForm = ({ submitText, submitCenter }) => {
    const [show, setShow] = useState(true)
    const [Loading, setLoading] = useState(false);
    const { register, reset, handleSubmit } = useForm()

    const onSubmit = (data) => {
        setLoading(true)

        const formValues = {
            name: `${data.firstName} ${data.lastName}`,
            mobile: data.mobile,
            email: data.email,
            message: `department: ${data.department} / course: ${data.course}`,
        }
        axios.post('https://ejnarstudios.com/ej-mailer/api/send-mail', formValues).then(response => {
            if (response.data.status) {
                Swal.fire({
                    title: 'Thank you!',
                    html: `<h4 class="mb-3">for reaching out to us!</h4>`,
                })
            } else {
                Swal.fire({
                    title: 'Whoo!',
                    text: response.data.message,
                    icon: 'warning',
                    confirmButtonText: 'Okay'
                })
            }
        }).finally(() => {
            reset()
            setLoading(false)
        })
    }
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-md-flex gap-3 w-100 mb-3">
                    <div class="form-floating w-100 mb-3 mb-md-0">
                        <input {...register('firstName', { required: true })} type="text" class="form-control" id="firstName" placeholder="" required />
                        <label for="firstName">First Name <sup className='text-danger'>*</sup></label>
                    </div>
                    <div class="form-floating w-100 mb-3 mb-md-0">
                        <input {...register('lastName', { required: true })} type="text" class="form-control" id="lastName" placeholder="" required />
                        <label for="lastName">Last Name <sup className='text-danger'>*</sup></label>
                    </div>
                </div>
                <div className="d-md-flex gap-3 w-100">
                    <div class="form-floating w-100 mb-3 mb-md-0">
                        <input {...register('email', { required: true })} type="email" class="form-control" id="email" placeholder="" required />
                        <label for="email">E-mail <sup className='text-danger'>*</sup></label>
                    </div>
                    <div class="form-floating w-100 mb-3 mb-md-0">
                        <input {...register('mobile', { required: true })} type="number" class="form-control" id="mobile" placeholder="" required />
                        <label for="mobile">Mobile <sup className='text-danger'>*</sup></label>
                    </div>
                </div>
                <div class="form-floating mt-3">
                    <input {...register('course', { required: true })} type="text" class="form-control" id="Course" placeholder="" required />
                    <label for="Course">Course <sup className='text-danger'>*</sup></label>
                </div>
                <div class="form-floating mt-3">
                    <input {...register('department')} type="text" class="form-control" id="Department" placeholder="" required />
                    <label for="Department">Department</label>
                </div>

                {
                    !submitText ?
                        <div class="mt-4 d-flex gap-3 text-white cursor-pointer" onClick={() => setShow(!show)}>
                            <div className='check-box cursor-pointer'>
                                <img src={require('../images/check-box.png')} />
                                {
                                    show ?
                                        <img src={require('../images/check-mark.png')} className='check-mark' />
                                        : null
                                }
                            </div>
                            <label class="form-check-label" for="defaultCheck1">
                                I hereby agree to receive emails, calls and SMS related to promotional activities and services, by or on services
                            </label>
                        </div>
                        : null
                }
                <Button size='lg' disabled={Loading} className={`${submitCenter ? 'text-center w-100' : ''} bg-primary-2 text-dark fw-bold rounded-0 mt-3`} variant='dark' type='submit'>
                    {!Loading ? submitText ? submitText : "Submit" : "Submitting..."}
                </Button>
            </form>
        </div>
    )
}

export default ContactForm