import React, { useContext } from 'react';
import { Button, Container, Nav, NavLink, Navbar } from 'react-bootstrap';
import { modalContext } from '../context';
const Header = () => {
    const { openModal } = useContext(modalContext)
    return (
        <>
            <Navbar className="bg-white" expand='lg' id='#'>
                <Container>
                    <Navbar.Brand href="#home">
                        <img src={require('../images/logo.png')} width={280} />
                    </Navbar.Brand>
                    <div className="d-none d-lg-flex gap-2">
                        <img width={165} src={require('../images/ash.gif')} />
                        <img width={117} src={require('../images/naaclogo.jpg')} />
                        <img width={50} src={require('../images/ugc.jpg')} />
                        <img width={50} src={require('../images/aicte.jpg')} />
                        <img width={50} src={require('../images/nirf.jpg')} />
                        <img width={50} src={require('../images/nba.jpg')} />
                        <img width={50} src={require('../images/info.jpg')} />
                    </div>
                </Container>
            </Navbar>
            <Navbar className="bg-primary" expand='lg' variant='dark'>
                <Container>
                    {
                        window.innerWidth < 992 ?
                            <div className='d-flex text-lg-start text-center justify-content-between w-100 align-items-center'>
                                <div className="lead fw-bold text-white">MENU</div>
                                <Navbar.Toggle />
                            </div>
                            : null
                    }

                    <Navbar.Collapse id="basic-navbar-nav">
                        <div className='d-lg-flex text-lg-start text-center justify-content-between w-100 align-items-center'>
                            <Nav>
                                <NavLink href="#" className='me-md-4' >Home</NavLink>
                                <NavLink href="#about" className='me-md-4' >About</NavLink>
                                <NavLink href="#course" className='me-md-4' >Course</NavLink>
                                <NavLink href="#contact">Contact us</NavLink>
                            </Nav>
                            <div className="d-md-flex  align-items-center">
                                <a href='tel:917395926859' className='text text-white me-md-3 my-3 my-md-0 d-block' target='_blank'>📞+91 73959 26859</a>
                                <Button variant='light' onClick={openModal}> For Admissions</Button>
                            </div>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header