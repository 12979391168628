import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const About = () => {
    return (
        <Container className='py-md-5 my-md-5 py-3' id='about'>
            <Row>
                <Col md='6' className='pe-md-5'>
                    <blockquote className='ps-3 ps-md-5'>
                        <div className="mb-md-5 mb-2">
                            <h3 className='text-primary'>History</h3>
                            <p>Aiming to bring about change and progress through education, the Tmt.Kannammal Educational Trust was constituted in 1985 and started Thai Moogambigai Polytechnic College. Within three years of its inception, Dr. M.G.R. Engineering College was founded in 1988, and the Thai Moogambigai Dental College was started in the year 1991. Both the colleges acquired Deemed University status in 2003 as Dr. M.G.R. Educational and Research Institute as per the orders of the University Grants Commission, New Delhi and the Union Ministry of Human Resources and Development, Government of India, New Delhi.</p>
                        </div>
                        <h3 className='text-primary'>Vission</h3>
                        <p className='mb-0'>The 21st century poses a lot of challenges that could be overcome with the help of education and technology. Keeping this in mind Dr. M.G.R Educational and Research Institute was started with a vision of providing a better foothold for the students of today to face the challenges. To succeed in the world today you need more than just academics. The modern adult is expected to have a combination of technical know-how and ambitious confidence. Being aware of the demand that is placed on the youth today, Dr. M.G.R. Educational and Research Institute Deemed to be University has preserved to nurture and groom young men and women through Engineering, Medical, Dental and Humanities and Sciences.</p>
                    </blockquote>
                </Col>
                <Col md='6' className='ps-md-5 mt-md-0 mt-4'>
                    <img src={require('../images/about-us.png')} className='w-100' />
                </Col>
            </Row>
        </Container>
    )
}

export default About