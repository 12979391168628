import React, { useContext } from 'react'
import { Container } from 'react-bootstrap'
import { modalContext } from '../context'
import { faculties } from '../data'

const Faculties = () => {
    const { openModal } = useContext(modalContext)

    return (
        <div className='text-primary' id='course'>
            <Container>
                <center>
                    <h3 className='text-primary'>Faculty</h3>
                    <p className='w-75 mx-auto'>Thai Moogambigai Dental College & Hospital | Dr. M.G.R. Engineering College | Dr. M.G.R. Educational and Research Institute
                        RajaRajeswari Medical College & Hospital, Bengaluru | A.C.S. Medical College & Hospital | Sri Lalithambigai Medical College & Hospital</p>
                    <img src={require('../images/title-design.png')} width={window.innerWidth < 992 ? 280 : 788} />
                </center>
                {
                    faculties.map((faculty, index) => (
                        <div key={index} className='my-md-5'>
                            <div className="d-md-flex mb-3 p-2 text-center text-md-start p-md-0 gap-4 align-items-center border border-primary rounded">
                                <img src={faculty.image} width={window.innerWidth < 992 ? '100%' : 400} className='mb-2 mb-md-0' />
                                <h3 className='m-0'>{faculty.title}</h3>
                            </div>
                            <div className="mt-md-5 row">
                                {
                                    faculty.courses.map((course, index2) => (
                                        <div key={index2} className='col-md-6 col-lg-4 my-3'>
                                            {
                                                course.title ?
                                                    <div className='fs-6 mb-4 fw-bold'><u>{course.title}</u></div>
                                                    : null
                                            }
                                            <ul className='ms-3 p-0 text-dark'>
                                                {
                                                    course.data.map((item, index2) => (
                                                        <li key={index2} className='mb-3'>
                                                            <a onClick={openModal} className='text'>{item}</a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </Container>
        </div>
    )
}

export default Faculties