import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { modalContext } from '../context';
import ContactForm from './ContactForm';
const Footer = () => {
    const { openModal } = useContext(modalContext)

    return (
        <footer id='contact'>
            <Container>
                <Row>
                    <Col lg='6' className='offset-lg-6 py-5'>
                        <h2 className='text-primary fw-bold'>FOR ADMISSIONS</h2>
                        <ContactForm />
                    </Col>
                    <Col md='12' className='text-md-start text-center'>
                        <div className="fs-5 text-white pb-1 border-bottom border-white mb-4"><span className="fw-bold">THE MGR UNIVERSITY</span><span className='fw-lighter'><span className='ms-2 d-md-inline-block d-none'>|</span>  Maduravoyal, Chennai, Tamil Nadu 600095</span></div>
                        <Row>
                            <Col lg='3'>
                                <h5 className='text-primary-2'><u>Quick Links</u></h5>
                                <a onClick={openModal} className='text d-block my-1'>Home</a>
                                <a onClick={openModal} className='text d-block my-1'>About us</a>
                                <a onClick={openModal} className='text d-block my-1'>Enquire now</a>
                            </Col>
                            <Col lg='3'>
                                <a onClick={openModal} className='text d-block my-1'>Engineering</a>
                                <a onClick={openModal} className='text d-block my-1'>Department of Arts</a>
                                <a onClick={openModal} className='text d-block my-1'>Animation & Viscom</a>
                                <a onClick={openModal} className='text d-block my-1'>Accounts & Commerce</a>
                            </Col>
                            <Col lg='3'>
                                <a onClick={openModal} className='text d-block my-1'>Deparment of Law</a>
                                <a onClick={openModal} className='text d-block my-1'>Allied Health Science</a>
                                <a onClick={openModal} className='text d-block my-1'>Department of management</a>
                                <a onClick={openModal} className='text d-block my-1'>Pharmacy, BPT & Nursing [UG & PG]</a>
                            </Col>
                            <Col lg='3'>
                                <a onClick={openModal} className='text d-block my-1'>Department of Architecture</a>
                                <a onClick={openModal} className='text d-block my-1'>Department of Hotel Management</a>
                                <a onClick={openModal} className='text d-block my-1'>Bachelor of Computer Application</a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <center className='bg-primary-2 mt-4 p-3'>
                Copyright @2024 All rights reserved by <span className='text-dark'>mgronlinedu.com</span>
            </center>
        </footer>
    )
}

export default Footer