export const faculties = [
    {
        title: 'Engineering Course Admissions Open 2024',
        image: require('../images/fac (1).png'),
        courses: [
            {
                title: null,
                data: [
                    "Bio-Technology",
                    "Civil Engineering",
                    "CSE-Data Science",
                    "Chemical Engineering",
                    "Computer Engineering",
                    "Bio Medical Engineering",
                    "Mechanical Engineering",
                    "Robotics and Automation",
                ],
            },
            {
                title: null,
                data: [
                    "Information Technology",
                    "Automobile Engineering",
                    "CSE-Artificial Intelligence",
                    "Bio Medical Instrumentation",
                    "Bio Medical Instrumentation",
                    "Computer Science & Engineering",
                    "Electrical & Computer Engineering",
                    "Construction Tech & Management",
                ],
            },
            {
                title: null,
                data: [
                    "Electrical & Computer Engineering",
                    "Electrical and Electronics Engineering",
                    "Instrumentation and Control Engineering",
                    "CSE-Data Science & Artificial Intelligence",
                    "Electronics and Communication Engineering",
                    "Electronics and Instrumentation Engineering"
                ],
            },
            {
                title: null,
                data: [
                    "(CFIS)Cyber Forensics and Information Security",
                    "Electronics and Telecommunication Engineering",
                    "Civil Engineering (IT Enabled Construction Tech & Management)",
                ],
            },
        ]
    },
    {
        title: 'Humanities and Sciences UG & PG Admissions Open 2024',
        image: require('../images/fac (2).png'),
        courses: [
            {
                title: 'DEPT OF ANIMATION & VISCOM',
                data: [
                    "B.Sc.-Animation & Viscom",
                    "B.Sc.-Advanced Animation",
                    "B.Sc-Augmented Reality & Virtual  Reality",
                    "B.Sc.-Animation & Viscom(Film Technology)",
                ]
            },
            {
                title: 'DEPARTMENT OF SCIENCE',
                data: [
                    "B.Sc-Fashion Design",
                    "B.Sc-Bio-Technology",
                    "B.Sc- Computer Science",
                    "B.Sc-Criminology and Forensic Science",
                    "B.Sc-Information Science & Cyber Forensics",
                ]
            },
            {
                title: 'DEPT OF ACCOUNTS & COMMERCE',
                data: [
                    "B.Com - General",
                    "B.Com - Accounting & Finance",
                    "B.Com - Computer Application",
                    "B.Com-Statistics AND Financial",
                    "B.Com - Corporate Secretaryship",
                    "B.Com - Cost and Management Accounting [CMA]",
                    "B.Com - Banking Financial Services and Insurance",
                ]
            },
            {
                title: 'DEPARTMENT OF ARTS',
                data: [
                    "B.A-HRM",
                    "B.A-English",
                    "B.A-Tamil Literature",
                    "B.A - Defense and Strategic Studies",
                    "B.P.E.S.- Bachelor of Physical Education and Sports",
                ]
            },
            {
                title: 'POSTGRADUATE COURSES',
                data: [
                    "M.Sc-Criminology",
                    "M. ComMA-English",
                    "M.Sc-Bio-Technology",
                    "M.Sc-Real Estate Valuation",
                    "M.Sc-Animation & Viscomm",
                    "M.Sc-Cyber Forensics & Information Science",
                ]
            }

        ]
    },
    {
        title: 'Management Courses UG & PG Admissions Open 2024',
        image: require('../images/fac (3).png'),
        courses: [
            {
                title: 'ALLIED HEALTH SCIENCE',
                data: [
                    "B.Sc Optometry",
                    "B. Sc Physician Assistant",
                    "B. Sc Medical Lab Technology",
                    "B.Sc.-Cardiac Care Technology",
                    "B. Sc Renal  Dialysis Technology",
                    "B. Sc Respiratory Care Technology",
                    "B. Sc O.T &  Anaesthesia Technology",
                    "B. Sc Cardiac & Perfusion Technology",
                    "B. Sc Radiology & Imaging  Science Technology",
                ]
            },
            {
                title: 'DEPARTMENT OF MANAGEMENT',
                data: [
                    "MBA-PT",
                    "MBA-General",
                    "MBA-Business Analytics",
                    "BBA - Retail management",
                    "MBA-Financial Management",
                    "BBA - Financial Planning [FP]",
                    "MBA-Human Resource Management",
                    "BBA - Bachelor of Business Administration",
                    "MBA-Logistics & Supply Chain Management",
                ]
            },
            {
                title: 'Pharmacy, BPT & Nursing (UG&PG)',
                data: [
                    "MPT- 2 Years",
                    "BPT- 4 ½  Years",
                    "Pharm D - 6 Years",
                    "B. Pharm - 4 Years",
                    "D. Pharm - 2 Years",
                    "B.Sc-Nursing- 4 Years",
                    "M.Sc-Nursing - 2 Years",
                ]
            },
            {
                title: 'DEPARTMENT OF LAW',
                data: [
                    "LLB - 3 Years",
                    "B.A.LLB - 5 Years",
                    "B.B.A.LLB - 5 Years",
                    "B.COM.LLB - 5 Years",
                ]
            },
            {
                title: 'Department of ARCHITECTURE',
                data: [
                    "B.ARCH",
                    "M.ARCH",
                    "B.Design",
                    "Landscape",
                    "Interior Design",
                    "B.ARCH-5 YEARS",
                    "Construction Project Management",
                ]
            },
            {
                title: 'Department of Hotel Management',
                data: [
                    "BA-Economics",
                    "BA-Culinary Arts",
                    "B.Sc-Psychology",
                    "BA-Political Science",
                    "M.Sc-Clinical Psychology",
                    "BA-Public Administration",
                    "MA-Indian Cuisine & Food Culture",
                    "M.Sc.-Food Science, Nutrition & Dietetics",
                    "B.Sc.-Hotel Management & Catering Technology",
                ]
            }, {
                title: 'Bachelor of Computer Application',
                data: [
                    "BCA-Artificial Intelligence and Data Sciences",
                    "Bachelor of Computer Application",
                    "BCA - Gaming with Designing",
                    "BCA-Digital Technology",
                    "MCA",
                ]
            }
        ]
    }
]