import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Outlet, useLocation } from 'react-router-dom'
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'
import Header from './components/Header'
import { modalContext } from './context'

const Layout = () => {
    const { pathname } = useLocation()
    const [showModel, setModel] = useState(false);
    const [closeButton, setCloseButton] = useState(true);
    const handleClose = () => setModel(false);
    const openModal = () => setModel(true);

    useEffect(() => {
        if (pathname === '/contact-us') {
            setCloseButton(false)
        }
        setModel(true)
    }, [pathname])

    return (
        <modalContext.Provider value={{ showModel, openModal }}>
            <Header />
            <Outlet />
            <Footer />
            <Modal show={showModel} onHide={handleClose} backdrop="static" keyboard={false} >
                <div className='bg-footer'>
                    <Modal.Header closeButton={closeButton} closeVariant='white' className='border-0'>
                        <Modal.Title className='fs-5 text-white w-100 text-center'>Admissions Open 2024</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><ContactForm submitText='APPLY NOW' submitCenter={true} /></Modal.Body>
                </div>
            </Modal>
        </modalContext.Provider>
    )
}

export default Layout